import * as React from "react";
import { Button } from "@mui/material";
import "./what-does-dave-bring-with-him.css";
import ContactModal from "./contact-modal";
import Modal from "@mui/material/Modal";
import ContactModalThankYou from "./contact-modal-thank-you";

const WhatDoesDaveBringWithHim = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [thankyouOpen, setThankyouOpen] = React.useState(false);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ContactModal
          setModalOpen={setModalOpen}
          setThankyouOpen={setThankyouOpen}
        />
      </Modal>
      <Modal open={thankyouOpen} onClose={() => setThankyouOpen(false)}>
        <ContactModalThankYou setThankyouOpen={setThankyouOpen} />
      </Modal>
      <section className="what-does-dave-bring-with-him">
        <div className="what-does-dave-bring-with-him1">
          <h1 className="what-does-dave">{`What does Dave bring with him? `}</h1>
          <div className="with-dave-you">{`With Dave, you gain a partner who understands the challenges you face and is committed to your success. His wealth of knowledge, passion for making a difference, and extensive experience equip him with unique insights to guide and empower you. `}</div>
          <Button
            className="button41"
            endIcon={
              <img width="18px" height="22px" src="/masked-icon-1.svg" />
            }
            variant="contained"
            sx={{
              fontSize: "var(--typography-body2-size)",
              color: "#fff",
              fontFamily: "var(--typography-body2)",
              textTransform: "capitalize",
              letterSpacing: "0.15px",
              lineHeight: "175%",
              fontWeight: 500,
              borderRadius: "5px",
              background: "#05182b",
              "&:hover": { background: "#05182b" },
              textTransform: "none",
              width: 218,
              height: 44,
            }}
            onClick={() =>
              window.open("https://www.linkedin.com/in/thedavewescott/")
            }
          >
            Connect with Dave
          </Button>
        </div>
        <div className="what-i-do-cards">
          <div className="bootstrap-focused-storytelling">
            <h3 className="bootstrap-focused-storytelling1">
              Bootstrap-Focused Storytelling
            </h3>
            <div className="inspiring-tales-of">
              Inspiring tales of resourcefulness—discover how Dave has forged
              progress through sheer determination and creative solutions.
            </div>
          </div>
          <div className="philanthropic-mindset">
            <h3 className="philanthropic-mindset1">Philanthropic Mindset</h3>
            <div className="embrace-the-power">
              Embrace the power of generosity—let Dave’s experiences catalyze
              your drive to create lasting change and a meaningful impact for
              society.
            </div>
          </div>
          <div className="entrepreneurial-expertise-and">
            <h3 className="entrepreneurial-expertise-and1">
              Entrepreneurial Expertise and Dedication
            </h3>
            <div className="partner-with-dave">
              Partner with Dave to nurture your entrepreneurial spirit—the
              unwavering commitment and knowledge to fuel your journey toward
              achievement.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatDoesDaveBringWithHim;

import * as React from "react";
import { Button } from "@mui/material";
import "./speaking-engagement-coming-up.css";
import ContactModal from "./contact-modal";
import ContactModalThankYou from "./contact-modal-thank-you";
import Modal from "@mui/material/Modal";

const SpeakingEngagementComingUp = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [thankyouOpen, setThankyouOpen] = React.useState(false);

  return (
    <>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ContactModal
          setModalOpen={setModalOpen}
          setThankyouOpen={setThankyouOpen}
        />
      </Modal>
      <Modal open={thankyouOpen} onClose={() => setThankyouOpen(false)}>
        <ContactModalThankYou setThankyouOpen={setThankyouOpen} />
      </Modal>
      <section className="speaking-engagement-coming-up">
        <h1 className="do-you-have">
          Do you have a speaking engagement coming up?
        </h1>
        <div className="ready-to-overcome">
          Ready to overcome challenges and reach new heights with Dave's
          support? Tap into the power of entrepreneurial storytelling to ready
          your journey to success. It's time to transform your dreams into
          reality.
        </div>
        <Button
          className="button42"
          endIcon={<img width="18px" height="22px" src="/masked-icon-1.svg" />}
          variant="contained"
          sx={{
            textTransform: "none",
            color: "#fff",
            background: "#9a9b9f",
            borderRadius: "5px",
            "&:hover": { background: "#9a9b9f" },
            width: 227,
            height: 44,
            fontSize: "var(--typography-body2-size)",
            fontFamily: "var(--typography-body2)",
            letterSpacing: "0.15px",
            lineHeight: "175%",
            fontWeight: 500,
          }}
          onClick={() => setModalOpen(true)}
        >
          Let's Work Together
        </Button>
      </section>
    </>
  );
};

export default SpeakingEngagementComingUp;

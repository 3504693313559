import * as React from "react";
import { useMemo } from "react";
import "./honey4-hope.css";
import { navigate } from "gatsby";

const Honey4Hope = ({
  unsplash8V4yXXT3MQ,
  sweetenTheWorldOneJarAtAT,
  honey4Hope,
  everyDropEveryJarResonate,
  honey4HopeHexLogo1,
  propWidth,
  href,
}) => {
  const honey4HopeHexLogo1IconStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div className="honey4hope">
      <img
        className="unsplash8v4y-xxt3mq-icon"
        loading="eager"
        alt=""
        src={unsplash8V4yXXT3MQ}
      />
      <div className="frame-container">
        <div className="frame-div">
          <div className="sweeten-the-world-one-jar-at-parent">
            <div className="sweeten-the-world">{sweetenTheWorldOneJarAtAT}</div>
            <div className="honey4hope1">{honey4Hope}</div>
          </div>
          <div className="every-drop-every">{everyDropEveryJarResonate}</div>
        </div>
        <div className="button-group">
          <button className="button37" onClick={() => navigate(href)}>
            <div className="base19">
              <img className="masked-icon38" alt="" src="/masked-icon.svg" />
              <div className="button38">Go there now</div>
              <img className="masked-icon39" alt="" src="/masked-icon-1.svg" />
            </div>
          </button>
          <div className="honey4hope-hexlogo-1-wrapper">
            <img
              className="honey4hope-hexlogo-1-icon"
              loading="eager"
              alt=""
              src={honey4HopeHexLogo1}
              style={honey4HopeHexLogo1IconStyle}
            />
          </div>
        </div>
        {/* <div className="button-parent">
          <button className="button35">
            <div className="base18" onClick={() => navigate(href)}>
              <img className="masked-icon36" alt="" src="/masked-icon.svg" />
              <div className="button36">Go there now</div>
              <img className="masked-icon37" alt="" src="/masked-icon-1.svg" />
            </div>
          </button>
          <div className="honey4hope-hexlogo-1-wrapper">
            <img
              className="honey4hope-hexlogo-1-icon"
              loading="eager"
              alt=""
              src={honey4HopeHexLogo1}
              style={honey4HopeHexLogo1IconStyle}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Honey4Hope;
